
import router from '@/router';
import { generalStore } from '@/store';
import { defineComponent, ref, computed } from 'vue';
import { AddProductInExistOrderRequest } from '@/models/Interfaces';
import { OrderDetails, OrderProductCustomerDetails } from '@/models/Order';
import useOrder from '@/modules/useOrder';
import useOrderCustomers from '@/modules/useOrderCustomers';
import Q from 'q';
import swal from 'sweetalert2';
import { ProductCustomer } from '@/models/Order';
import { onBeforeRouteLeave } from 'vue-router';
import { useI18n } from 'vue-i18n';
import mixin from '@/mixin';
import Dropdown from 'primevue/dropdown';
import OrderHeader from '@/components/Order/OrderHeader.vue';
import OrderProductPrice from '@/components/Order/OrderProductPrice.vue';
import { CouponDiscountType, CouponLimitationType } from '@/models/Enums';
import OrderMobileActionsFooter from '@/components/Order/OrderMobileActionsFooter.vue';
import InputNumber from 'primevue/inputnumber';
import { createDebounce } from '@/helper';
import { CalendarStopSale } from '@/models/Calendar';
import { api } from '@/services/Api';

export default defineComponent({
    components: {
        Dropdown,
        OrderHeader,
        OrderProductPrice,
        OrderMobileActionsFooter,
        InputNumber
    },
    async setup() {
        const { AddProductInOrder, AddProductInExistOrder } = useOrder();
        const { t } = useI18n();
        const { getMinutes, canLeave } = useOrder();
        const couponLimitationType = CouponLimitationType;

        const wasValidated = ref(false);

        onBeforeRouteLeave(async to => {
            return await canLeave(to);
        });
        const order = ref<OrderDetails>(new OrderDetails());
        const {
            valid,
            customerTypes,
            calculated,
            qtyNotOversized,
            availableTypes,
            getCustomerPrice,
            getCustomerPriceCurrencySymbol,
            GetProductCustomers,
            CalculateEventProductCustomers,
            CalculateOnClickProductCustomers
        } = useOrderCustomers(order);

        swal.showLoading();
        await Q.delay(400);
        order.value = generalStore.getters.getNewOrder;
        const canChangeUnitPrice = generalStore.getters.canChangeUnitPrice;
        function pageDataExist() {
            if (!order.value) {
                return false;
            }
            if (!order.value.priceTypeId) {
                return false;
            }
            if (!order.value.newproduct.productId) {
                return false;
            }
            if (!order.value.newproduct.slotId) {
                return false;
            }
            if (!order.value.newproduct.date) {
                return false;
            }
            return true;
        }

        if (!pageDataExist()) {
            router.replace({ name: 'neworder' });
            swal.close();
            return;
        }
        GetProductCustomers();

        const dateToTitle = computed<string>(() => mixin.methods.getDateToTitle(order.value.newproduct.date));

        if (order.value.newproduct.customers.length === 0) {
            order.value.newproduct.customers.push(new OrderProductCustomerDetails());
        }

        const showAddCustomType = computed<boolean>(() => {
            if (!customerTypes) {
                return false;
            }
            if (order.value.newproduct.customers.length >= customerTypes.value.length) {
                return false;
            }
            return true;
        });

        function calcLeftCoupons() {
            for (let i = 0; i < order.value.newproduct.customers.length; i++) {
                const customer = order.value.newproduct.customers[i];
                if (!customer.customerInfo.couponId) {
                    continue;
                }
                const coupon = order.value.coupons.find(x => x.id == customer.customerInfo.couponId);
                if (!coupon) {
                    continue;
                }
                let totalUsed = 0;
                if (coupon.limitationType == CouponLimitationType.Ticket) {
                    totalUsed = customer.quantity;
                } else {
                    if (coupon.discountType == CouponDiscountType.Discount) {
                        totalUsed = customer.discountAmount;
                    } else if (coupon.discountType == CouponDiscountType.NoDiscount || coupon.discountType == CouponDiscountType.TicketFixedUnitPrice) {
                        totalUsed = customer.price;
                    }
                }
                coupon.leftLimitationTotal -= Number(totalUsed);
            }
        }

        function alreadyExistProduct() {
            const customerIds = order.value.newproduct.customers.map(x => x.id);
            const existCustomer = order.value.products.find(
                x =>
                    x.productId == order.value.newproduct.productId &&
                    x.customers.some(
                        c => customerIds.includes(c.id) && c.date == `${order.value.newproduct.date}T${order.value.newproduct.startTime}`
                    )
            );
            if (existCustomer) {
                const ddd = existCustomer.customers.find(
                    c => customerIds.includes(c.id) && c.date == `${order.value.newproduct.date}T${order.value.newproduct.startTime}`
                );
                return ddd?.name ?? 'Unknown customer';
            }
            return '';
        }

        const submitForm = async (event: any) => {
            wasValidated.value = true;
            event.target.classList.add('was-validated');
            if (!event.target.checkValidity() || !valid.value) {
                event.preventDefault();
                event.stopPropagation();
            } else {
                let ignoreQty = false;
                if (!qtyNotOversized.value) {
                    const swalResult = await swal.fire({
                        title: t('alert.areYouSure'),
                        text: t('alert.youHaveExceededTheQuantity'),
                        icon: 'warning',
                        customClass: {
                            confirmButton: 'save-button-wrapper popup-bookit-button my-1 px-4',
                            cancelButton: 'close-button-wrapper popup-bookit-button my-1 px-5'
                        },
                        buttonsStyling: false,
                        showCancelButton: true,
                        confirmButtonText: t('alert.yesContinue'),
                        cancelButtonText: t('button.cancel')
                    });
                    if (!swalResult.isConfirmed) return;
                    ignoreQty = true;
                }
                if (order.value.id) {
                    if (!qtyNotOversized.value) {
                        await Q.delay(150);
                    }
                    const req: AddProductInExistOrderRequest = {
                        orderId: order.value.id,
                        product: {
                            productId: order.value.newproduct.productId,
                            priceId: order.value.priceTypeId,
                            slotId: order.value.newproduct.slotId,
                            price: order.value.newproduct.price,
                            date: order.value.newproduct.date,
                            startDateTime: `${order.value.newproduct.date}T${order.value.newproduct.startTime}`,
                            customers: order.value.newproduct.customers.map(x => ({
                                couponId: x.customerInfo.couponId,
                                discountAmount: x.discountAmount,
                                priceForOne: x.priceForOne,
                                id: x.id,
                                quantity: x.quantity,
                                date: null,
                                slotId: order.value.newproduct.slotId
                            }))
                        },
                        ignoreQty: ignoreQty
                    };
                    const ok = await AddProductInExistOrder(req);
                    if (ok) {
                        router.push({ name: 'order', params: { id: order.value.id } });
                    }
                } else {
                    const name = alreadyExistProduct();
                    if (name) {
                        swal.fire({
                            icon: 'error',
                            text: t('neworder.product-already-exist', [name])
                        });
                        return;
                    }
                    calcLeftCoupons();
                    await AddProductInOrder(order.value.newproduct);
                    router.push({ name: 'neworder' });
                }
            }
        };

        const addCustomerType = () => {
            calculated.value = false;
            order.value.newproduct.customers.push(new OrderProductCustomerDetails());
        };
        const back = () => {
            router.push({ name: 'addorderproductslot' });
        };

        const deleteRow = async (index: number) => {
            calculated.value = false;
            order.value.newproduct.customers.splice(index, 1);
            if (
                !order.value.newproduct.customers ||
                order.value.newproduct.customers.length === 0 ||
                order.value.newproduct.customers.some(x => !x.quantity || x.quantity == 0 || !x.id)
            ) {
                return;
            }
            await CalculateOnClickProductCustomers();
        };

        function changeQty(customer: ProductCustomer) {
            customer.priceCalculated = false;
        }

        function isWarning(customer: OrderProductCustomerDetails) {
            if (
                calculated.value &&
                order.value.newproduct.notAvailableCustomerTypeIds &&
                order.value.newproduct.notAvailableCustomerTypeIds.length
            ) {
                return order.value.newproduct.notAvailableCustomerTypeIds.includes(customer.id);
            }
            return calculated.value && !order.value.newproduct.isAvailable;
        }

        async function backToOrder() {
            const alert = await swal.fire({
                title: t('alert.areYouSure'),
                text: t('alert.ifYouLeaveThePage'),
                icon: 'warning',
                customClass: {
                    confirmButton: 'save-button-wrapper popup-bookit-button my-1 px-4',
                    cancelButton: 'close-button-wrapper popup-bookit-button my-1 px-5'
                },
                buttonsStyling: false,
                showCancelButton: true,
                confirmButtonText: t('alert.yesContinue'),
                cancelButtonText: t('button.cancel')
            });
            if (alert.isConfirmed) {
                if(order.value.newproduct.slot.id && order.value.newproduct.slot.isNew){
                    swal.showLoading();
                    const slot = new CalendarStopSale();
                    slot.slotId = order.value.newproduct.slot.id;
                    const stopSaleResult = await api.createCalendarStopSale(slot, false, true);

                    if (stopSaleResult.errorMessage) {
                        swal.fire({ icon: 'error', text: stopSaleResult.errorMessage });
                        return;
                    }
                    swal.hideLoading();
                }

                generalStore.commit('clearOrderNewProduct');
                router.push({ name: 'neworder' });
            }
        }
        function setDiscount(event: any, customer: OrderProductCustomerDetails) {
            customer.discountAmount = event.value ?? 0;
        }

        async function onChangeCustomerType(event: any, customer: OrderProductCustomerDetails) {
            calculated.value = false;
            customer.discountAmount = 0;
            customer.priceForOne = getCustomerPrice(event.value.id);
            CalculateEventProductCustomers(customer);
        }

        async function onInputPriceForOne(event: any, customer: OrderProductCustomerDetails) {
            customer.priceForOne = event.value;
            customer.limitExceeded = false;
            calculated.value = false;
        }

        swal.close();

        return {
            order,
            back,
            submitForm,
            addCustomerType,
            showAddCustomType,
            availableTypes,
            debounce: createDebounce(),
            deleteRow,
            CalculateOnClickProductCustomers,
            dateToTitle,
            getMinutes,
            changeQty,
            valid,
            qtyNotOversized,
            calculated,
            CalculateEventProductCustomers,
            getCustomerPrice,
            getCustomerPriceCurrencySymbol,
            isWarning,
            wasValidated,
            couponLimitationType,
            backToOrder,
            setDiscount,
            canChangeUnitPrice,
            onChangeCustomerType,
            onInputPriceForOne
        };
    }
});
